import api from './api';


// 查询订单调度和订单列表
export const $sg_queryOrderDispatch = (params) => api.get('/j/distribution/agent/order/management/queryOrderDispatchV2', params);
// 获取订单详情
export const $sg_getThridOrderDetail = (params) => api.get('/j/distribution/agent/order/management/getThridOrderDetail', params);
// 代理商订单统计情况,进行中的订单,今日订单,本月订单,总单数
export const $sg_getOrderStatistics = (params) => api.get('/j/distribution/agent/order/management/getOrderStatistics', params);

// 查询骑手列表
export const $sp_pageOnlineRider = (params) => api.postNoMessage('/j/distribution/agent/rider/management/pageOnlineRider', params);

// 指派骑手
export const $sp_orderManagementSuccess = (params) => api.postNoMessage('/j/distribution/agent/order/management/assign/order', params);
// 异常订单转派骑手
export const $sp_reassignOrder = (params) => api.post('/j/distribution/agent/order/management/reassign/order', params);

// 骑手当前已接单信息
export const $sg_getRiderOrderInfos = (params) => api.get('/j/distribution/agent/rider/management/getRiderOrderInfos', params);

// 新增取件/跑腿业务设置
export const $sp_distributionRunErrandsSave = (params) => api.post('/j/distribution/agent/distributionRunErrands/save', params);

// 修改取件/跑腿业务设置
export const $sp_distributionRunErrandsUpdate = (params) => api.post('/j/distribution/agent/distributionRunErrands/update', params);

// 查询取件/跑腿业务设置
export const $sg_queryConfigByType = (params) => api.get('/j/distribution/agent/distributionRunErrands/queryConfigByType', params);

// 第三方调度
export const $sg_toThirdChannel = (params) => api.getNoTips('/j/distribution/agent/order/management/toThirdChannel', params);
// 指派转单骑手
export const $sg_replayOrder = (params) => api.postNoMessage('/j/distribution/agent/order/management/assign/replayOrder', params);
//  查询是否开启了转单设置
export const $sg_queryOpenRelayTokenByAgentId = (params) => api.get('/j/distribution/agent/order/management/queryOpenRelayTokenByAgentId',
  params);
// 代理商账户以及最多欠费金额查询
export const $sg_selectAgentMaxFund = (params) => api.get('/api/agent/biz/agent/selectAgentMaxFund', params);
// 查询订单列表
export const $sg_queryPageList = (params) => api.get('/j/distribution/agent/order/management/queryOrderDispatch', params);
// 骑手查询转派纪录
export const $sg_queryRiderRedeployByAgent = (params) => api.get('/j/distribution/agent/tAgentRiderRedeploy/queryRiderRedeployByAgent', params);
