<template>
  <div class="assignRiderDialog">
    <el-dialog title="转派骑手" :visible.sync="riderDialog" width="1300px" class="rider-dialog">
      <div class="rider-dialog-content">
        <div class="rider-dialog-tabel">
          <div class="rider-dialog-search">
            <span class="mr10">查询骑手</span>
            <el-input v-model="riderInfo" class="inp" clearable placeholder="输入骑手名字或电话" :maxlength="20"></el-input>
            <el-button type="primary" class="btn" @click="searchRider('search')">查询</el-button>
          </div>
          <div class="rider-dialog-table">
            <el-table :data="riderTableData" border style="width: 100%" height="400" ref="riderTable" @row-click="selectRider"
              :row-style="rowStyle" :row-class-name="tableRowClassName" v-loading="riderLoading" element-loading-text="数据获取中...">
              <el-table-column prop="riderName" align="center" label="姓名"></el-table-column>
              <el-table-column prop="riderPhone" align="center" label="电话"></el-table-column>
              <el-table-column align="center" label="距离商户">
                <template slot-scope="scope">
                  <span> {{ scope.row.riderDistance }}km </span>
                </template>
              </el-table-column>
              <el-table-column prop="riderState" align="center" label="当前骑手状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.riderState == 3">配送中</span>
                  <span v-if="scope.row.riderState == 2">抢单中</span>
                </template>
              </el-table-column>
              <el-table-column prop="professionType" align="center" label="岗位">
                <template slot-scope="scope">
                  <span v-if="scope.row.professionType == 1">全职</span>
                  <span v-if="scope.row.professionType == 2">兼职</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination class="mt10" background :page-count="riderTotalPage" :current-page.sync="riderCurrentPage"
            @current-change="riderPageChange(1)">
          </el-pagination>
          <div class="rider-dialog-footerBtn">
            <el-button @click="riderDialog = false">取消</el-button>
            <PrevReclick>
              <el-button type="primary" @click="assignRider">确认转派</el-button>
            </PrevReclick>
          </div>
        </div>
        <div class="rider-dialog-tabel">
          <div class="rider-riderOrderInfo">
            <span class="mr10">骑手当前已接订单信息</span>
            <span>今日单量{{
                orderInfo.riderOrderStatisticVo.underwayOrderCount || 0
              }}/
              {{
                orderInfo.riderOrderStatisticVo.totalOrder || 0
              }}(进行中/总接单量)</span>
            <span class="rider-dialog-riderPort" @click="riderposition(RiderOrderInfo.riderId)">骑手定位</span>
          </div>
          <div class="rider-dialog-table">
            <el-table :data="orderInfo.riderOrderInfos.records" border style="width: 100%" height="400" ref="riderTable"
              v-loading="riderOrderInfoLoading" element-loading-text="数据获取中...">
              <el-table-column align="center" label="订单类型">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.orderType === 1
                      ? "外卖配送"
                      : orderTypeDataLists[scope.row.orderSubType]
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="状态(已用时)" width="140">
                <!-- 用时  当前时间减 接单时间 -->
                <template slot-scope="scope">
                  <p>{{ dialogOrderType[scope.row.orderState] }}</p>
                  <p>({{ getMinutes(scope.row.receiveTime, 1) }}min)</p>
                </template>
              </el-table-column>
              <el-table-column align="center" label="期望送达时间" width="160">
                <template slot-scope="scope">
                  <div v-if="scope.row.orderType === 1">
                    <p>{{ scope.row.expectArriveTime }}</p>
                    <p>
                      剩余时间：{{
                        getMinutes(scope.row.expectArriveTime, 2)
                      }}min
                    </p>
                    <!-- 期望送达时间 - 当前时间 -->
                  </div>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="商家名称" width="180" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span v-if="scope.row.orderType === 1">{{
                    scope.row.shopName || "-"
                  }}</span>
                  <span v-else>-</span>
                </template>
              </el-table-column>

              <el-table-column label="收发地址" width="200">
                <template slot-scope="scope">
                  <div v-if="scope.row.orderSubType <= 3">
                    <p>
                      <span v-if="scope.row.orderSubType <= 2">取：</span>
                      <span v-else-if="
                          scope.row.orderType !== 1 &&
                          scope.row.orderSubType === 3
                        ">买：</span>
                      <span>{{ scope.row.shopName }}-</span>
                      <span>{{ scope.row.shopTel }}-</span>
                      <span>{{ scope.row.fetchAddress }}</span>
                    </p>
                    <p>
                      送：
                      <span>{{ scope.row.contactName }}-</span>
                      <span>{{ scope.row.contactTel }}-</span>
                      <span>{{ scope.row.deliveryAddress }}</span>
                    </p>
                  </div>
                  <!-- 帮排队 全能帮手 -->
                  <div v-if="
                      scope.row.orderSubType >= 6 && scope.row.orderType != 1
                    ">
                    <p>
                      <span v-if="scope.row.orderSubType === 6">排队地址：</span>
                      <span v-else>地址：</span>
                      <span>{{ scope.row.deliveryAddress }}</span>
                    </p>
                    <p>
                      联系人姓名：<span>{{ scope.row.contactName }}</span>
                    </p>
                    <p>
                      联系电话：<span>{{ scope.row.contactTel }}</span>
                    </p>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination class="mt10" background :page-count="orderInfoTotal" :current-page.sync="RiderOrderInfo.pageCurrent"
            @current-change="riderPageChange(2)">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  $sp_pageOnlineRider,
  $sg_getRiderOrderInfos,
} from '@/api/delivery';

export default {
  data() {
    return {
      orderRiderRequest: {
        orderId: '',
        riderId: '',
        riderLat: '',
        riderLng: '',
      },
      RiderOrderInfo: {
        // 获取骑手接单信息
        riderId: null,
        pageCurrent: 1,
        pageSize: 10,
      },
      orderInfoTotal: 1,
      riderLoading: false, // 骑手列表加载
      riderDialog: false,
      riderInfo: '',
      riderTotalPage: 0,
      riderCurrentPage: 1,
      riderTableData: [],
      orderInfo: {
        riderOrderInfos: {}, // 订单列表
        riderOrderStatisticVo: {}, // 订单统计
      }, // 骑手订单信息
      riderOrderInfoLoading: false, // 骑手订单信息加载
      orderTypeDataLists: [
        '',
        '帮我取',
        '帮我送',
        '帮我买',
        '',
        '',
        '帮排队',
        '全能帮手',
        '外卖配送',
      ],
      dialogOrderType: ['', '已接单', '已到店', '配送中'],
    };
  },
  methods: {
    // 打开转派骑手弹窗
    openRiderDialog(orderId) {
      this.RiderOrderInfo.riderId = null;
      this.RiderOrderInfo.pageCurrent = 1;
      this.orderInfo.riderOrderInfos = {};
      this.orderInfo.riderOrderStatisticVo = {};
      this.orderRiderRequest = {
        orderId,
      };
      this.riderDialog = true;
      this.searchRider();
    },
    // 关闭转派骑手弹窗
    closeRiderDialog() {
      this.riderDialog = false;
    },
    // 查询骑手
    searchRider(type = '') {
      const params = {
        pageCurrent: this.riderCurrentPage,
        pageSize: 10,
        searchKey: this.riderInfo,
      };
      this.riderLoading = true;
      $sp_pageOnlineRider(params)
        .then((res) => {
          this.riderTableData = res.records;
          this.riderTotalPage = res.pages;
          if (!res.total && type) {
            this.$message.error('请输入正确信息');
          }
        })
        .finally(() => {
          this.riderLoading = false;
        });
    },
    // 获取骑手接单信息
    getRiderOrderInfos() {
      $sg_getRiderOrderInfos(this.RiderOrderInfo)
        .then((res) => {
          const { riderOrderInfos, riderOrderStatisticVo } = res;
          if (riderOrderInfos) {
            this.orderInfo.riderOrderInfos = riderOrderInfos;
          }
          if (riderOrderStatisticVo) {
            this.orderInfo.riderOrderStatisticVo = riderOrderStatisticVo;
          }
        })
        .finally(() => {
          this.riderOrderInfoLoading = false;
        });
    },
    // 骑手列表翻页
    riderPageChange(type) {
      if (type === 1) {
        this.searchRider();
      } else {
        this.getRiderOrderInfos();
      }
    },
    // 选择骑手
    selectRider(row) {
      this.orderRiderRequest.riderId = row.id;
      this.orderRiderRequest.riderLat = row.latitude;
      this.orderRiderRequest.riderLng = row.longitude;
      this.riderOrderInfoLoading = true;
      this.RiderOrderInfo.riderId = row.id;
      this.getRiderOrderInfos();
    },
    // 更改选中行背景色
    rowStyle({ row }) {
      if (this.orderRiderRequest.riderId === row.id) {
        return { 'background-color': '#F7EDED', cursor: 'pointer' };
      }
      return { cursor: 'pointer' };
    },
    // 转派订单
    assignRider() {
      if (!this.orderRiderRequest.riderId) {
        this.$message.error('请选择骑手');
        return;
      }
      this.$emit('assignOrder', this.orderRiderRequest);
    },
    // 翻页
    pageChange() {
      this.getList();
    },
    tableRowClassName({ row }) {
      if (row.id === this.RiderOrderInfo.riderId) {
        return 'success-row';
      }
      return '';
    },
    getMinutes(time, type) {
      if (!time) {
        return '';
      }
      const nowTime = new Date().getTime();
      const oldTime = new Date(time).getTime();
      const timeDis = type === 1 ? (nowTime - oldTime) / 1000 : (oldTime - nowTime) / 1000;
      return (timeDis / 60).toFixed(0);
    },
    // 查看骑手定位
    riderposition(riderId) {
      if (!riderId) {
        this.$message.error('获取骑手位置失败');
        return;
      }
      this.$router.push({
        path: '/main/delivery/riderposition',
        query: {
          riderId,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.assignRiderDialog {
  width: 100%;
  // 转派骑手弹窗
  .rider-dialog {
    .inp {
      width: 180px;
    }
    .el-dialog {
      border-radius: 6px;
    }
    .el-dialog__header {
      height: 46px;
      background-color: #f8f7f7;
      padding: 0px;
      padding-left: 35px;
      border-radius: 6px;
      .el-dialog__title {
        line-height: 46px;
        font-size: 18px;
        color: #333333;
        font-weight: bold;
      }
      .el-dialog__headerbtn {
        top: 17px;
      }
    }
    .el-dialog__body {
      padding: 0 20px 0;
    }
    .el-dialog__footer {
      border-bottom: 20px solid #f8f7f7;
      border-radius: 6px;
      padding: 0;
    }
    .rider-dialog-footerBtn {
      margin: 20px 0;
      text-align: right;
    }
    .rider-dialog-content {
      display: flex;
      justify-content: space-between;
      .rider-dialog-tabel {
        width: calc(50% - 10px);
      }
      .rider-riderOrderInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 35px;
        padding-top: 26px;
      }
      .rider-dialog-riderPort {
        cursor: pointer;
        color: #ef3f46;
        text-decoration: underline;
      }
    }
    .el-button {
      width: 110px;
      height: 32px;
    }
    .el-button--default {
      color: #999999;
      border: 1px solid #ececec;
    }
    .el-button--primary {
      margin-left: 20px;
      margin-right: 25px;
    }
    // 取消表格鼠标进入高亮显示
    .el-table__row:hover > td {
      background-color: transparent;
    }
    .rider-dialog-search {
      font-size: 14px;
      color: #666666;
      padding-top: 26px;
      .btn {
        width: 80px;
        height: 32px;
        margin-left: 36px;
        background-color: #666666;
        border-color: #666666;
      }
    }
    .rider-dialog-table {
      margin-top: 26px;
      // padding: 0px 5px;
    }
  }
  .mr60 {
    margin-right: 60px;
  }
  .mt26 {
    margin-top: 26px;
  }
  .mt14 {
    margin-top: 14px;
  }
  .mt46 {
    margin-top: 46px;
  }
}
</style>
