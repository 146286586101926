import api from './api';

// 获取订单列表
export const $sg_getOrderList = (params) => api.get('/order/v1/order/getOrderList', params);
// 获取退款列表分页数据
export const $sg_getApplyRefundList = (params) => api.get('/order/v1/order/getApplyRefundList', params);
// 获取店铺基本信息
export const $sg_getShopInfo = () => api.get('/mall/v1/ShopInfo/getShopInfo');
// 获取店铺打印设置
export const $sg_checkMobilePrint = () => api.get('/mall/v1/ShopManager/checkMobilePrint');
// 获取订单列表分类统计
export const $sg_getShopOrderCount = (params) => api.get('/order/v1/order/getShopOrderCount', params);
/**
 * @api {post} /mall/v1/Shop/getBindShopList 获取绑定子店铺列表
 * @apiGroup Shop
 * @apiVersion 0.0.0
 * @apiHeader (请求头) {String} authorization 登录成功返回的token信息
 * @apiParam {int} [shop_id] 店铺id
 * @apiSuccessExample 成功示例:
 *  {
 *      "code": 1000,
 *      "msg": "操作成功",
 *      "data": [],
 *  }
 */
export const $sg_getBindShopList = (params) => api.get('/mall/v1/Shop/getBindShopList', params);
// 买单记录看板统计接口
export const $sp_getPayTheBillStatistics = (params) => api.get('/order/v1/order/getPayTheBillStatistics', params);
// 到店买单订单列表
export const $sg_listOrderConsumes = (params) => api.get('/order/v1/order/listOrderConsumes', params);
// 退款通用接口
export const $sp_refundOrder = (params) => api.post('/platform/v3/RefundOrder/refundOrder', params);
// 撤销订单
export const $sp_cancelOrder = (params) => api.post('/order/v2/OrderEvent/cancelOrder', params);
// 商家自配送将订单改为配送中状态
export const $sp_setOrderDistribution = (params) => api.post('/order/v1/OrderEvent/setOrderDistribution', params);
// 仅外卖商城订单，手动将订单发快跑者
export const $sp_sendOrderToKeloop = (params) => api.postNoReject('/order/v1/OrderEvent/sendOrderToKeloop', params);
// 受理订单
export const $sp_acceptOrder = (params) => api.post('/order/v1/OrderEvent/acceptOrder', params);
// 打印订单
export const $sp_printOrder = (params) => api.post('/shop/v1/Order/printOrder', params);
// 获取平台配置
export const $sp_partialOrder = (params) => api.post('/account/v1/Refund/partialOrder', params);
// 店铺获取余额  params order_id 订单ID
export const $sp_addThirdorder = (params) => api.post('/deliver/v1/Order/addThirdorder', params);
// 验证是否能发往第三方配送  params order_id 订单ID
export const $sp_checkThirdOrder = (params) => api.post('/deliver/v1/order/checkThirdOrder', params);
//
export const $sg_orderInfo = (params) => api.get('/deliver/v1/Order/orderInfo', params);
// 获取商家对商品的权限配置和营销配置
export const $sgw_getShopAuthorityV2 = () => api.getNoMessage('/platform/v2/SpreadConfig/getShopAuthorityV2');
// 驳回申请
export const $sp_shopRefund = (params) => api.post('/order/v3/OrderEvent/shopRefund', params);
// 获取某订单平台的抽成明细和店铺收益明细
export const $sg_getFinanceCommission = (params) => api.get('/finance/v1/Finance/getFinanceCommission', params);
// {get} /order/v2/order/getOrderListV2 历史订单列表
export const $sp_getOrderListV2 = (params) => api.get('/order/v2/order/getOrderListV2', params);

// 代理商订单
export const $sg_findAgentOrderDTO = (params) => api.postNoMessage('/j/agent/order/findAgentOrderDTO', params);

// 跑腿订单数，分润查询
export const $sg_errandOrdersProfit = (params) => api.get('/j/distribution/agent/tDistributionFeeDim/errandOrdersProfit', params);

// 分页查询跑腿订单列表
export const $sg_pageErrandOrder = (params) => api.get('/j/distribution/agent/tDistributionFeeDim/pageErrandOrder', params);
// 跑腿订单详情 
export const $sg_getRunLegOrderInfo = (params) => api.get('/j/distribution/agent/runLeg/getRunLegOrderInfo', params);
// 骑手收益 /j/distribution/agent/tDistributionFeeDim/getRiderAwardList
export const $sg_getRiderAwardList = (params) => api.get('/j/distribution/agent/tDistributionFeeDim/getRiderAwardList', params);
// 骑手收益统计 /j/distribution/agent/tDistributionFeeDim/getTotalRiderAward
export const $sg_getTotalRiderAward = (params) => api.get('/j/distribution/agent/tDistributionFeeDim/getTotalRiderAward', params);
// 异常订单列表
export const $sg_abnormalOrderList = (params) => api.get('/j/distribution/agent/ExOrder/pageList', params);
// 异常订单完成
export const $sp_abnormalOrderFinish = (params) => api.post('/j/distribution/agent/ExOrder/finish', params);
