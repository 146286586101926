<template>
  <div class="abnormalOrder">
    <div class="searchBox flexbox flex-wrap">
      <div class="searchItem">
        <span>配送单号</span>
        <el-input v-model="form.skOrderId" style="width: 200px;" placeholder="请输入配送单号" clearable></el-input>
      </div>
      <div class="searchItem">
        <span>订单号</span>
        <el-input v-model="form.deliveryId" style="width: 200px;" placeholder="请输入订单号" clearable></el-input>
      </div>
      <!-- <div class="searchItem">
        <span>订单类型</span>
        <el-select v-model="form.orderType" style="width: 160px;">
          <el-option
            v-for="item in orderTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div> -->
      <div class="searchItem">
        <span>订单状态</span>
        <el-select v-model="form.orderStatus" style="width: 160px;">
          <el-option
            v-for="item in orderStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="searchItem">
        <span>时间筛选</span>
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="searchItem">
        <el-button type="primary" @click="search">筛选</el-button>
      </div>
      <div class="searchItem">
        <el-button type="info" @click="clear">清空</el-button>
      </div>
      <div class="searchItem">
        <PrevReclick>
          <el-button type="primary" @click="exportExOrder">导出数据</el-button>
        </PrevReclick>
      </div>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="deliveryId" label="订单号" align="center"></el-table-column>
        <el-table-column prop="orderType" label="订单类型" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.orderType == 1">外卖</div>
            <div v-if="scope.row.orderType == 2">
              <span>跑腿-</span>
              <span v-if="scope.row.orderSubType == 1">帮我取</span>
              <span v-else-if="scope.row.orderSubType == 2">帮我送</span>
              <span v-else-if="scope.row.orderSubType == 3">帮我买</span>
              <span v-else-if="scope.row.orderSubType == 4">跑腿</span>
              <span v-else-if="scope.row.orderSubType == 5">大件业务</span>
              <span v-else-if="scope.row.orderSubType == 6">帮排队</span>
              <span v-else-if="scope.row.orderSubType == 7">全能帮手</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="skOrderId" label="配送单号" align="center"></el-table-column>
        <el-table-column prop="riderName" label="骑手姓名" align="center"></el-table-column>
        <el-table-column prop="riderPhone" label="骑手联系方式" align="center"></el-table-column>
        <el-table-column prop="orderCreateTime" label="订单创建时间" align="center"></el-table-column>
        <el-table-column prop="exceptionType" label="异常原因" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.exceptionType == 1">无法联系到商家</span>
            <span v-else-if="scope.row.exceptionType == 2">无法联系上用户</span>
            <span v-else-if="scope.row.exceptionType == 4">商家未出餐</span>
            <span v-else-if="scope.row.exceptionType == 5">其它原因</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="exceptionRemark" label="骑手描述" align="center"></el-table-column>
        <el-table-column prop="createTime" label="时间" align="center"></el-table-column>
        <el-table-column prop="" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="openAssignOrderDialog(scope.row.skOrderId)">转派</el-button>
            <el-button type="text" @click="openCompleteOrderDialog(scope.row.skOrderId, scope.row.riderId)">完成</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="totalBox">
      <el-pagination background :page-count="totalPage" :current-page.sync="pageCurrent" @current-change="pageChange"></el-pagination>
    </div>
    <!-- 转派弹窗 -->
    <assignOrderDialog ref="assignOrderDialog" @assignOrder="assignOrder"></assignOrderDialog>
    <!-- 完成订单弹窗 -->
    <el-dialog
      title="完成确定"
      :visible.sync="completeOrderDialog"
      width="500px"
      :close-on-click-modal="false">
        <div class="completeOrderTips">若对订单有疑问，可联系骑手取消异常订单</div>
        <el-input type="textarea" :rows="4" placeholder="请输入完成确定原因" v-model="remark"></el-input>
        <span slot="footer" class="flexbox flex-justify-center">
          <el-button @click="completeOrderDialog = false">暂不完成</el-button>
          <el-button type="primary" @click="completeOrder">完成订单</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import { $sg_abnormalOrderList, $sp_abnormalOrderFinish } from '@/api/order';
import { $sp_reassignOrder } from '@/api/delivery';
import { exportFileSync } from '@base/utils';
import assignOrderDialog from './components/assignOrderDialog.vue';

export default {
  components: {
    assignOrderDialog,
  },
  data() {
    return {
      pageCurrent: 1,
      pageSize: 10,
      totalPage: 0,
      form: {
        deliveryId: '',
        skOrderId: '',
        orderStatus: '',
        orderType: '',
      },
      time: '',
      orderTypeOptions: [
        {
          value: '',
          label: '全部',
        }, 
        {
          value: 1,
          label: '外卖',
        }, 
        {
          value: 2,
          label: '跑腿',
        },
      ],
      orderStatusOptions: [
        {
          value: '',
          label: '全部',
        },
        // {
        //   value: 1,
        //   label: '待接单',
        // },
        {
          value: 2,
          label: '已接单',
        },
        {
          value: 3,
          label: '已到店',
        },
        {
          value: 4,
          label: '配送中',
        },
        // {
        //   value: 5,
        //   label: '配送完成',
        // },
        // {
        //   value: 6,
        //   label: '撤销订单',
        // },
      ],
      tableData: [],
      completeOrderDialog: false,
      skOrderId: '',
      riderId: '',
      remark: '', // 完成原因
    };
  },
  created() {
    this.search();
  },
  methods: {
    // 筛选
    search() {
      this.pageCurrent = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.pageCurrent = 1;
      this.form = {
        deliveryId: '',
        skOrderId: '',
        orderStatus: '',
        orderType: '',
      };
      this.time = '';
      this.getTableData();
    },
    // 获取订单列表
    getTableData() {
      const params = {
        pageCurrent: this.pageCurrent,
        pageSize: this.pageSize,
        ...this.form,
        startTime: '',
        endTime: '',
      };
      if (this.time && this.time.length > 0) {
        params.startTime = this.time[0] / 1000;
        params.endTime = this.time[1] / 1000;
      }
      $sg_abnormalOrderList(params).then((res) => {
        this.totalPage = res.pages;
        this.tableData = res.records;
      });
    },
    // 导出订单
    exportExOrder() {
      const params = {
        ...this.form,
        startTime: '',
        endTime: '',
      };
      if (this.time && this.time.length > 0) {
        params.startTime = this.time[0] / 1000;
        params.endTime = this.time[1] / 1000;
      }
      exportFileSync(
        '/j/distribution/agent/ExOrder/exportExOrder',
        params,
        'post',
        '异常订单.xlsx',
      ).then(() => {});
    },
    // 打开转派订单弹窗
    openAssignOrderDialog(id) {
      this.$refs.assignOrderDialog.openRiderDialog(id);
    },
    // 转派订单
    assignOrder(orderRiderRequest) {
      const params = {
        ...orderRiderRequest,
        takeOrderType: 3,
      };
      $sp_reassignOrder(params).then(() => {
        this.$refs.assignOrderDialog.closeRiderDialog(); // 关闭弹窗
        this.getTableData();
      });
    },
    // 打开完成订单弹窗
    openCompleteOrderDialog(skOrderId, riderId) {
      this.skOrderId = skOrderId;
      this.riderId = riderId;
      this.remark = '';
      this.completeOrderDialog = true;
    },
    // 完成订单
    completeOrder() {
      if (!this.remark) {
        this.$message.error('完成确定原因不能为空');
        return;
      }
      const params = {
        skOrderId: this.skOrderId,
        riderId: this.riderId,
        remark: this.remark,
      };
      $sp_abnormalOrderFinish(params).then(() => {
        this.completeOrderDialog = false;
        this.getTableData();
      });
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.abnormalOrder {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  .searchBox {
    width: 100%;
    .searchItem {
      flex-shrink: 0;
      margin-top: 20px;
      margin-right: 20px;
      & > span {
        margin-right: 10px;
        color: #444;
      }
    }
  }
  .tableBox {
    margin-top: 40px;
    .el-table {
      min-height: 580px;
    }
  }
  .totalBox {
    margin-top: 20px;
  }
  .completeOrderTips {
    color: #F63D29;
    margin-bottom: 20px;
  }
}
</style>
